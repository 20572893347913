import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Belgium",
  "subtitle": "May 2017",
  "category": "Europe"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Belgian_waffle"
        }}>{`Beigum Waffles`}</a>{` - Honestly the best ones are from trucks with no toppings!`}</li>
    </ul>
    <h4 {...{
      "id": "chocolate"
    }}>{`Chocolate`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hkgPSDWXttQNh4it8"
        }}>{`Dumon Chocolatier`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/6mztT2FjJ468g2R57"
        }}>{`Leonidas`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/2Lpj3TnWvz2nFcXAA"
        }}>{`Neuhaus`}</a>{` - Was an apothecary in the past and said to have invented pralines. The `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Neuhaus_(chocolatier)"
        }}>{`history`}</a>{` was interesting.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/TjBRKjbg4xJETZaG6"
        }}>{`Pierre Marcolini`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qy6t1fwb6yGMcaDE8"
        }}>{`The Chocolate line`}</a></li>
    </ul>
    <h2 {...{
      "id": "antwerp"
    }}>{`Antwerp`}</h2>
    <ul>
      <li parentName="ul">{`Storage available at central`}</li>
    </ul>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/SFK6HU57hyKxTBa86"
        }}>{`De Arme Duivel`}</a>{` - Stoofvlees (beef stew) was very good`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PwK2E37Kik1KUJ899"
        }}>{`Plantin-Moretus Museum`}</a>{` - Unique history about printing and publishing!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AysmKmqLYytiKbX16"
        }}>{`Rubenshuis`}</a>{` - Enjoyed it but audio guide not available`}</li>
    </ul>
    <h2 {...{
      "id": "brugge"
    }}>{`Brugge`}</h2>
    <ul>
      <li parentName="ul">{`I preferred Ghent`}</li>
      <li parentName="ul">{`Most shops won’t swipe a credit card unless the purchase is €10+`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/2TxPLCacZtXfsvhJ9"
        }}>{`Belfry Tower`}</a>{` - There’s a fence at the top so it blocks the view`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NjoMkhYr2jYBUGXU9"
        }}>{`Groeningem Museum`}</a>{` - Extensive collection of Flemish art`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.harpmuziek.be/"
        }}>{`Luc Vanlaere Harp Concert`}</a>{` - Really nice solo Harpist concert!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/LNqA2DJaNgRJhEMM9"
        }}>{`Stadhuis`}</a>{` - Gothic hall was pretty`}</li>
    </ul>
    <h2 {...{
      "id": "ghent"
    }}>{`Ghent`}</h2>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/fLjpVkYX6J4PZkVQ6"
        }}>{`De Kastart`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/8qPWpDRtWM7whqmL7"
        }}>{`Frituur Sint-Jacobs`}</a>{` - Hand-cut fries`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qjwPzvajN7btKzhh8"
        }}>{`Het Belfort van Gent`}</a>{` -  I was told a story where Ghent stole the Golden Dragon (on top of the tower) from Brugges`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7vmNypSxtUixdtVw6"
        }}>{`St. Bavo’s Cathedral`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Famous altarpiece Mystic Lamb is very crowded however audio guide was included`}</li>
          <li parentName="ul">{`Watched a concert - “Jaarconcert Schola Cantorum Gent - ‘Requiem’ W.A. Mozart"`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/KaSrVt1BQXoQjjN26"
        }}>{`Stam Museum`}</a>{` - Spent about 2 hours on the city's history`}</li>
    </ul>
    <h2 {...{
      "id": "brussels"
    }}>{`Brussels`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mobib.be/"
        }}>{`MOBIB`}</a>{` for transportation`}</li>
    </ul>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/UbQEBdPMA4hti41y5"
        }}>{`Gaston`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/j5ExAZYPdycGdb2S9"
        }}>{`Nuetnigenough`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-3"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HPqEVSnZcK953nPK8"
        }}>{`Brussels Town Hall`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Mzu4B7bwhFRXc9io9"
        }}>{`Église Notre-Dame du Sablon`}</a>{` - Beautiful stained glass`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/s9AjntnNxzzo4iZPA"
        }}>{`Jeanneke Pis`}</a>{` - In the Delirium Cafe alley but it's gated so I'd say only check it out if you're in the area`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PTLRHhXhdadZ2aYK7"
        }}>{`Kleine Zavelsquare`}</a>{` - Great view of Eglise`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/aFF2MtmUDs4fdh1K7"
        }}>{`Les Galeries Royales Saint-Hubert`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/i1mGZRSU3v5VuN7v8"
        }}>{`Manneken Pis`}</a>{` - Very crowded`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/CHYhUHH8nb7iZKWPA"
        }}>{`Mont de Arts`}</a>{` - Reminds me of Paris`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hHKKzbTBKnxJMXgG7"
        }}>{`Musée des Instruments de musique`}</a>{` - Great museum and audio guide`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nrcdbuc5oFNs9ipf8"
        }}>{`Musées Royaux des Beaux-Arts`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The Old Masters collection was my favorite`}</li>
          <li parentName="ul">{`You can buy the single ticket first and then upgrade to the combination ticket if you visit more than one section`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/9NKp5kVgy3sRfQ1KA"
        }}>{`Parc du Cinquantenaire`}</a>{` - Closed on Mondays`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/6YPjsdgknP3X8NQc8"
        }}>{`St Michael Cathedral`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      